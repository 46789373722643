import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`👋, I am Manan Agarwal.`}</h1>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`I'm a 23-year-old professional based in Mumbai, India.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`I am mostly active on Twitter: `}<a parentName="p" {...{
            "href": "https://x.com/manan_0308"
          }}>{`Manan Agarwal`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`I like building LEGO sets, like `}<a parentName="p" {...{
            "href": "https://x.com/manan_0308/status/1789583024352280918"
          }}>{`this one`}</a>{` or `}<a parentName="p" {...{
            "href": "https://x.com/manan_0308/status/1787076238395547863"
          }}>{`this one`}</a>{` or `}<a parentName="p" {...{
            "href": "https://x.com/manan_0308/status/1784570914455404599"
          }}>{`THIS ONE`}</a>{` when I am not watching Instagram reel edits of India losing the last cricket world cup.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Always up to chat around a lot of themes : navigating early careers in tech, product management, Bombay vs Bangalore, Umesh vs Rameshwaram who does a better dosa and many more things!`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      