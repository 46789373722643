import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Projects`}</h1>
    <h2><a parentName="h2" {...{
        "href": "https://twitter.com/internfinder"
      }}>{`InternFinder`}</a></h2>
    <p>{`InternFinder is a twitter bot built on Node.js that helps people find internships off Twitter. InternFinder was launched on `}<a parentName="p" {...{
        "href": "https://www.producthunt.com/posts/internfinder"
      }}>{`ProductHunt`}</a>{` and hit 100+ upvotes within 24 hours. It reached 7500 followers (and counting ) on Twitter within 1 year 6 months of launch. It averages anywhere between 50-80k profile visits a month today and has helped numerous folks get internships.`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://www.productx.io/"
      }}>{`ProductX.io`}</a></h2>
    <p>{`An effort to collate and publish all the knowledge, tools and resources which can help you enter and grow in the field of Product Management.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      